import { createSlice } from '@reduxjs/toolkit';

const trainingSlice = createSlice({
  name: 'training',
  initialState: {
    selectedService: null,
  },
  reducers: {
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
  },
});

export const { setSelectedService } = trainingSlice.actions;
export default trainingSlice.reducer;


