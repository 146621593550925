import React, { useMemo, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { AiOutlineUser, AiOutlineClockCircle } from "react-icons/ai";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaShareAlt,
  FaWhatsapp,
} from "react-icons/fa";
import axios from "axios";
import DOMPurify from "dompurify"; // Importing DOMPurify for sanitizing HTML
import { FiClock } from "react-icons/fi";
import { RiUserFill } from "react-icons/ri";

const BlogPostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        "https://xpandcms.azurewebsites.net/api/content/67a07f64239c7c8af4a74235",
        {
          headers: {
            Authorization:
              "Bearer 0bae8bbc32e2535ff2488a63a39ef14a2d6177efb2c3efe79fd46f092044112c",
          },
        }
      )
      .then((response) => {
        setAllPosts(response.data.post || []);
        const currentPost = response.data.post.find(
          (post) => post.slug === slug
        );
        setPost(currentPost);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
        setIsLoading(false);
      });
  }, [slug]);

  const relatedPosts = useMemo(() => {
    if (!post) return [];

    // Ensure categories are arrays
    const postCategories = Array.isArray(post.category) ? post.category : post.category.split(',');
 

    return allPosts.filter((relatedPost) => {
      // Ensure related post categories are arrays as well
      const relatedPostCategories = Array.isArray(relatedPost.category)
        ? relatedPost.category
        : relatedPost.category.split(',');
    

      // Check for category match
      const hasMatchingCategory = relatedPostCategories.some((category) =>
        postCategories.includes(category)
      );

      return relatedPost.id !== post.id && hasMatchingCategory;
    });
  }, [post, allPosts]);

  
  const shareUrl = window.location.href;

  if (isLoading) {
    return (
      <div className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <div className="h-64 w-full bg-gray-300 animate-pulse rounded-t-lg"></div>
          <div className="h-6 bg-gray-300 animate-pulse w-2/3"></div>
          <div className="h-6 bg-gray-300 animate-pulse w-1/3"></div>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8">
        Blog post not found
      </div>
    );
  }

  // Sanitize the post content to avoid any XSS attacks
  const sanitizedContent = DOMPurify.sanitize(post.content);

  return (
    <div
      className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundImage: `url('/images/blog2.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-3xl mx-auto">
        {/* "All posts" Link at the Top */}
        <div className="flex space-x-4 mb-4 font-roboto">
          <div className="border-r border-gray-300 border-t-0 border-b-0 border-l-0 pr-4">
            <Link to="/blog" className="text-blue-400 hover:underline">
              posts
            </Link>
          </div>
          <div className="border-r border-gray-300 border-t-0 border-b-0 border-l-0 pr-4">
            <Link to="/enrol" className="text-blue-400 hover:underline">
              enrol
            </Link>
          </div>
          <div>
            <Link to="/contact" className="text-blue-400 hover:underline">
              help
            </Link>
          </div>
        </div>

        <article className="bg-white rounded-lg shadow-md overflow-hidden mb-8 transition duration-300 ease-in-out transform">
          {/* Featured Image */}
          <img
            src={post.featuredImage}
            alt={post.title}
            className="w-full h-64 object-cover rounded-t-lg"
          />

          <div className="p-6">
            <h1 className="text-3xl font-semibold text-gray-900 mb-4">
              {post.title}
            </h1>

            {/* Post Date and Author */}
            <div className="flex flex-auto font-thin text-sm">
              <div className="flex items-center text-gray-600 mb-2 mr-3">
                <FiClock className="mr-1" />
                <span>
                  {new Date(post.createdAt)
                    .toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })
                    .toUpperCase()}
                </span>
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <RiUserFill className="mr-1" />
                <span>{post.username}</span>
              </div>
            </div>

            {/* Render sanitized content as HTML */}
            <div
              className="text-gray-800 mb-6"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            ></div>

            {/* Social Media Share Buttons with 'Share' message */}
            <div className="flex space-x-4 mt-6 items-center">
              <span className="text-gray-600">Share</span>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                <FaFacebookF className="text-2xl" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-600"
              >
                <FaTwitter className="text-2xl" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-900"
              >
                <FaLinkedinIn className="text-2xl" />
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(post.title)}%20${encodeURIComponent(shareUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500 hover:text-green-700"
              >
                <FaWhatsapp className="text-2xl" />
              </a>
              <a
                href={`https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange-500 hover:text-orange-700"
              >
                <FaShareAlt className="text-2xl" />
              </a>
            </div>
          </div>
        </article>

        {/* Related Posts Section */}
        {relatedPosts.length > 0 && (
          <section className="mt-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Related Posts
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {relatedPosts.map((relatedPost) => (
                <div
                  key={relatedPost.id}
                  className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105"
                >
                  <Link to={`/blog/${relatedPost.slug}`}>
                    <img
                      src={relatedPost.featuredImage}
                      alt={relatedPost.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="text-xl font-semibold text-gray-900">
                        {relatedPost.title}
                      </h3>
                      <div className="text-sm text-gray-600 mt-2">
                        <FiClock className="inline mr-1" />
                        <span>
                          {new Date(relatedPost.createdAt)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default BlogPostDetail;
