import React, { useState } from "react";
import { FaRocket, FaGamepad, FaPaintBrush, FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import CodingChallenge from "../components/CodingChallenge";

const KidsCoding = () => {
  const [isRevealed, setIsRevealed] = useState(false);

  return (
    <div className="p-1 md:p-5 bg-kids h-fit flex flex-col items-center justify-center relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 z-[-1] bg-opacity-20">
        <div
          className="absolute inset-0 bg-blue-200 opacity-30 animate-pulse"
          style={{ animation: "float 20s infinite ease-in-out" }}
        ></div>
        <div
          className="absolute inset-0 bg-pink-200 opacity-20 animate-pulse"
          style={{ animation: "float 30s infinite ease-in-out" }}
        ></div>
      </div>

      {/* Header */}
      <h1 className="text-5xl z-10 p-3 h-fit font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500 mb-1 shadow-lg animate-pulse">
        Kids Coding!
      </h1>

      {/* Image */}
      <div className="relative w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl mb-3 transform hover:scale-105 transition-transform duration-300">
        <img
          src="images/kidsCodings.gif"
          alt="Kids coding gif"
          className="w-full h-auto"
        />
      </div>

      {/* Description */}
      <p className="text-2xl font-bold text-blue-600 mb-6 text-center leading-relaxed bg-yellow-100 p-4 rounded-lg shadow-lg border-2 border-yellow-300">
        Here we make coding <span className="text-red-500">fun</span> and{" "}
        <span className="text-green-500">easy</span> for kids.
      </p>

      {/* Features List */}
      <ul className="list-disc list-inside text-lg text-purple-800 mb-6 space-y-3 text-center">
        <li className="flex items-center space-x-2 hover:text-purple-600 transition-colors duration-300">
          <FaRocket className="text-yellow-400 text-2xl" />
          <span>Interactive lessons</span>
        </li>
        <li className="flex items-center space-x-2 hover:text-purple-600 transition-colors duration-300">
          <FaGamepad className="text-green-400 text-2xl" />
          <span>Fun coding games</span>
        </li>
        <li className="flex items-center space-x-2 hover:text-purple-600 transition-colors duration-300">
          <FaPaintBrush className="text-pink-400 text-2xl" />
          <span>Creative projects</span>
        </li>
      </ul>

      <CodingChallenge />

      {/* Call-to-Action Button */}
      <Link to="/contact">
        <button className="px-6 py-3 bg-yellow-500 text-white rounded-full shadow-lg hover:bg-yellow-600 transition-colors duration-300 transform hover:scale-105 relative">
          <span className="text-lg font-semibold">
            Get your{" "}
            <span className="text-blue-500 inline-block rotate-[-15deg] transform px-1">
              Kid
            </span>{" "}
            Started
          </span>
        </button>
      </Link>

      {/* Animated Mascot */}
      <div className="absolute bottom-0 left-0 p-4">
        <img
          src="images/Mascot.jpg"
          alt="Animated mascot"
          className="w-12 h-12 md:w-32 md:h-32 animate-bounce rounded-full"
        />
      </div>



      {/* Click-to-Reveal Sections */}
      <div className="mt-8">
        <button
          className="px-6 py-3 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300"
          aria-expanded={isRevealed}
          aria-controls="reveal"
          onClick={() => setIsRevealed(!isRevealed)}
        >
          Explore Our Interactive Course
        </button>
        {isRevealed && (
          <div
            id="reveal"
            className="mt-4 text-center md:bg-white border border-gray-200 rounded-lg relative"
          >
            {/* Download Button */}


            {/* Modern heading and description */}
            {/* <h2 className="text-2xl font-semibold text-gray-800 mb-3">
              Explore Our Interactive Course!
            </h2> */}
            <h2 className="text-2xl font-semibold text-gray-800 mb-3">
              Dive your kid into our engaging lessons and exciting coding
              activities designed to make learning enjoyable!
            </h2>

            {/* PDF Viewer */}
            <div className="bg-gray-900 flex items-center justify-end  p-1 text-white hover:text-gray-400">      <a
              href="Kids Code.pdf" // Path to your PDF
              download="Kids_Code.pdf" // Filename for download
              className="  transition-colors duration-300"
            >
              <FaDownload className="text-3xl w-5 mr-10 " />
            </a></div>
            <div className="relative w-full mi-h-screen sm:h-[90vh] border border-gray-200  overflow-hidden">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
              >
                <Viewer fileUrl="Kids Code.pdf" />
              </Worker>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KidsCoding;
