import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'; // Corrected import
import { courses } from "../data/Courses";
import { FaArrowLeft, FaSearch, FaSpinner, FaDownload } from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import PdfViewer from "../components/PdfViewer";

// VendorCard Component 
const VendorCard = ({ vendorName, onClick }) => {
  return (
    <div
      onClick={() => onClick(vendorName)}
      className="cursor-pointer bg-white shadow-lg rounded-lg p-5 hover:scale-105 transition-all duration-300 ease-in-out transform hover:shadow-2xl"
    >
      <img
        src={`/images/${vendorName.toLowerCase()}.png`} // Assuming the images are named according to the vendor
        alt={vendorName}
        className="h-20 object-cover mx-auto mb-4"
      />
      <p className="text-center font-semibold text-lg text-gray-800">
        {vendorName}
      </p>
    </div>
  );
};

const Courses = () => {
  const navigate = useNavigate();  // Corrected line: useHistory to useNavigate
  const { vendor } = useParams();  // Properly captures the vendor URL parameter
  const [selectedVendor, setSelectedVendor] = useState(vendor || null);
  const [showCourses, setShowCourses] = useState(vendor !== null);
  const [searchVendor, setSearchVendor] = useState("");
  const [searchCourse, setSearchCourse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false); //Bool for PDF Viewer

  //Button text
  const btnText = isOpen ? "Close" : "View Training Schedule";


  // Reference to scroll to the back button
  const backButtonRef = useRef(null);

  useEffect(() => {
    if (!selectedVendor) {
      setShowCourses(false);  // Automatically show vendor list if no vendor is selected
    }
  }, [selectedVendor]);

  // List of vendors we want to display
  const vendors = useMemo(() => [
    "Microsoft",
    "CompTIA",
    "Cisco",
    "IBM",
    "ITIL",
    "PECB",
    "POPIA",
    "Certnexus",
    "ALCATEL",
    "AGRISETA",
    "Soft Skills",
    "Mict Seta"
  ], []);

  // Handle URL parameter on component mount
  useEffect(() => {
    console.log("Current vendor param:", vendor);
    console.log("Current vendors list:", vendors);
    
    if (vendor) {
      // Convert vendor to lowercase for case-insensitive comparison
      const vendorLowerCase = vendor.toLowerCase();
      console.log("Vendor lowercase:", vendorLowerCase);
      
      // Find the vendor that matches the URL parameter (case-insensitive)
      const matchedVendor = vendors.find(v => 
        v.toLowerCase() === vendorLowerCase
      );
      
      console.log("Matched vendor:", matchedVendor);
      
      if (matchedVendor) {
        // Set the matched vendor with proper casing
        setSelectedVendor(matchedVendor);
        setShowCourses(true);
      } else {
        // Log for debugging
        console.log(`No vendor match found for: ${vendor}`);
        console.log('Available vendors:', vendors);
        
        // If no match is found, stay on the courses page without redirecting
        setShowCourses(false);
        setSelectedVendor(null);
      }
    }
  }, [vendor, vendors]);

  // Ranking vendors according to trend (from most to least in demand)
  const trendRank = {
    Microsoft: 1,
    CISCO: 2,
    CompTIA: 3,
    ITIL: 4,
    IBM: 5,
    PECB: 6,
    POPIA: 7,
    Certnexus: 8,
    ALCATEL: 9,
    AGRISETA: 10,
    "Mict Seta": 11,
    "Soft Skills": 12,
  };

  // Sort the vendors array based on their ranking in `trendRank`
  const sortedVendors = vendors.sort((a, b) => trendRank[a] - trendRank[b]);

  // Filter vendors based on search input
  const filteredVendors = useMemo(() => {
    setIsLoading(true); // Start loading
    const result = vendors.filter((vendor) =>
      vendor.toLowerCase().includes(searchVendor.toLowerCase())
    );
    setIsLoading(false); // End loading
    return result;
  }, [searchVendor]);

  // Filter courses based on selected vendor and search input
  const filteredCourses = useMemo(() => {
    setIsLoading(true); // Start loading
    if (!selectedVendor) {
      setIsLoading(false); // End loading if no vendor selected
      return [];
    }
    const result = courses.filter(
      (course) =>
        course.categories.includes(selectedVendor) &&
        (course.displayName
          .toLowerCase()
          .includes(searchCourse.toLowerCase()) ||
          course.categories.some((category) =>
            category.toLowerCase().includes(searchCourse.toLowerCase())
          ))
    );
    setIsLoading(false); // End loading
    return result;
  }, [selectedVendor, searchCourse]);

  // Handle vendor card click to show courses
  const handleVendorClick = (vendorName) => {
    setSelectedVendor(vendorName); // Set new selected vendor
    setSearchVendor(''); // Clear vendor search field
    setSearchCourse('');  // Clear course search field
    setShowCourses(true); // Always show courses when a new vendor is selected
    navigate(`/courses/${vendorName.toLowerCase()}`);  // Navigate to /courses/{vendorName}
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // Handle back button to show vendor cards again
  const handleBackButtonClick = () => {
    setShowCourses(false); // Hide courses
    setSelectedVendor(null); // Deselect vendor
    setSearchVendor(''); // Clear vendor search field
    setSearchCourse('');  // Clear course search field
    navigate("/courses");  // Navigate back to /courses
  };

  return (
    <div className="min-h-screen bg-gray-50 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        <div className="mb-6 flex justify-center space-x-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 transition-colors duration-300" onClick={() => setIsOpen(!isOpen)}>{btnText}</button>
          {/* <a href={'/ITS-Training-Schedule-Jan-Jun-2025.pdf'}
            target="_blank"
             rel="noopener noreferrer">
          </a> */}
          {/* <a
            href={'/ITS-Training-Schedule-Jan-Jun-2025.pdf'}
            download="ITS-Training-Schedule-Jan-Jun-2025.pdf"
            className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-green-600 transition-colors duration-300"
          >
            Download Training Schedule
          </a> */}
        </div>

        {isOpen ? <PdfViewer /> : <div>
          {/* Search bar for vendor cards */}
          {!showCourses && (
            <div className="mb-6 max-w-lg mx-auto relative">
              <input
                type="text"
                placeholder="Search vendors..."
                value={searchVendor}
                onChange={(e) => setSearchVendor(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>
          )}

          {/* If courses are not visible, show vendor cards */}
          {!showCourses ? (
            <div>
              {/* Display message when no vendors are found */}
              {filteredVendors.length === 0 ? (
                <p className="text-center text-gray-500 text-xl">
                  No vendors found for this search.
                </p>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8">
                  {filteredVendors.map((vendor) => (
                    <VendorCard
                      key={vendor}
                      vendorName={vendor}
                      onClick={handleVendorClick}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            // Display selected vendor's courses
            <div>
              <button
                onClick={handleBackButtonClick}
                className="bg-blue-300 text-white px-4 py-1 rounded-lg mb-6 inline-flex items-center hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 transition duration-300"
              >
                <div className="flex flex-col justify-center items-center">
                  <FaArrowLeft className="w-5 h-5 mb-1" />
                  <span className="text-sm font-medium">Vendors</span>
                </div>
              </button>

              {/* Search bar for courses under the selected vendor */}
              <div className="mb-6 max-w-lg mx-auto relative">
                <input
                  type="text"
                  placeholder="Search courses..."
                  value={searchCourse}
                  onChange={(e) => setSearchCourse(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>

              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {selectedVendor} Courses
              </h3>
              <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {isLoading ? (
                  <div className="col-span-full text-center py-10">
                    <FaSpinner className="animate-spin w-6 h-6 mx-auto text-gray-500" />
                    <span className="text-lg font-semibold text-gray-500">Loading courses...</span>
                  </div>
                ) : filteredCourses.length === 0 ? (
                  <li className="text-center text-gray-500 col-span-full text-xl font-medium">
                    No courses found for this vendor.
                  </li>
                ) : (
                  filteredCourses.map((course) => (
                    <li
                      key={course.originalName}
                      className="bg-white shadow-md rounded-lg overflow-hidden transform hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out"
                    >
                      <a
                        href={`/Courses/schedule/${course.originalName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block p-6"
                      >
                        <div className="flex items-center space-x-4">
                          {/* Image */}
                          <img
                            src={course.imageUrl}
                            alt={course.displayName}
                            className="w-10 h-10 rounded object-cover"
                          />
                          {/* Text */}
                          <h3 className="text-sm font-semibold text-indigo-600 mb-2 hover:text-indigo-800 transition-colors duration-200">
                            {course.displayName}
                          </h3>
                        </div>
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
          )}
        </div>}
      </div>
    </div>
  );
};

export default Courses;
