import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FiArrowDown } from 'react-icons/fi';

const HeroSection = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <section className="relative w-full bg-gray-800 text-white py-20">
      <div className="absolute inset-0">
        <img src="/images/graduates.jpeg" alt="Career Background" className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-50" aria-hidden="true"></div>
      </div>
      <div className="relative container mx-auto px-6 text-center">
        <motion.h1
          className="text-3xl font-bold mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          Explore Our Learnerships and Graduates Programs
        </motion.h1>
        <motion.p
          className="text-lg mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Kickstart your career with our learnerships and graduate programs. Gain experience and skills. Apply now!
        </motion.p>
        <motion.a
          href="#opportunities"
          className="bg-customRed hover:bg-red-600 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 1 }}
        >
          Apply Now <FiArrowDown className="inline-block ml-2" />
        </motion.a>
      </div>
    </section>
  );
};

export default HeroSection;
