import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ImageSlideshow = () => {
  const [images, setImages] = useState<string[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://cms-backend-w1p9.onrender.com/api/content/6710f78b64b9de5448554a55', {
          headers: {
            'Authorization': 'Bearer e9b6c0207ecdd928383296944b2d8ce216a29d29b8c2f5b312de2520ecb8a198',
          },
        });

        if (response.data.gallery && Array.isArray(response.data.gallery)) {
          const urls = response.data.gallery.map((item) => item.image);
          setImages(urls);
        } else {
          setError('Failed to load images.');
        }
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Error fetching images.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (isPaused || images.length === 0) return;

    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId); // Cleanup on component unmount or when isPaused changes
  }, [isPaused, images.length]);

  const handlePrevSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  }, [images.length]);

  const handleNextSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  }, [images.length]);

  if (error) {
    return (
      <div className="w-full max-w-5xl mx-auto p-8 text-center">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-full max-w-5xl mx-auto p-8 text-center">
        <div className="animate-pulse">
          <div className="h-[600px] bg-gray-200 rounded-xl"></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative w-full max-w-5xl mx-auto p-2 md:p-10"
      onMouseEnter={() => setIsPaused(true)} // Pause on hover
      onMouseLeave={() => setIsPaused(false)} // Resume on mouse leave
    >
      <div className="relative h-[600px] md:rounded-xl overflow-hidden shadow-2xl">
        {images.length > 0 && (
          <img
            src={images[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
            className="absolute inset-0 w-full h-full object-cover transition-opacity duration-500"
          />
        )}

        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-black/20 pointer-events-none" />

        {/* Navigation buttons */}
        <button
          className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-all duration-200 text-white"
          onClick={handlePrevSlide}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-all duration-200 text-white"
          onClick={handleNextSlide}
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        {/* Slide indicators */}
        <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex gap-2">
          {images.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentSlide
                  ? 'bg-white w-6'
                  : 'bg-white/50 hover:bg-white/75'
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlideshow;
