 import React, { useState, useEffect, useRef } from 'react';
import EdTechWinnerSlide from '../components/EdTechWinnerSlide';
import { FaTrophy, FaAward, FaStar } from 'react-icons/fa';

const SuccessStory = () => {
  // Sample IoT-specific data
  const successStories = [
    {
      title: 'Michalene Smith',
      content:
        'The IoT program transformed my career by teaching me to design smart devices that communicate seamlessly.',
      image: '/images/sarah-lee.jpg',
      videoUrl: 'https://res.cloudinary.com/dgjzyis2j/video/upload/v1740041569/smith_1_t2hnqx.mp4',
      program: 'IoT',
    },
    {
      title: 'Kim Jody Olliver',
      content:'This experience has fueled my passion for sustainable tech solutions.',
      image: '/images/jake-moore.jpg',
      videoUrl: 'https://res.cloudinary.com/dgjzyis2j/video/upload/v1740040435/kim_azc28k.mp4',
      program: 'IoT',
    },
    {
      title: 'Keegan Kriel',
      content:
        'The IoT program equipped me with cutting-edge skills in AI and IoT integration.',
      image: '/images/keegan.jpg',
      videoUrl: 'https://res.cloudinary.com/dgjzyis2j/video/upload/v1740041602/keegan_1_b5xxpu.mp4',
      program: 'IoT',
    },
    {
      title: 'AfriTech Award Winner',
      content:
        'Recognized for excellence in EdTech innovation at the 2025 Africa Tech Summit',
      image: '/images/EdTechBadge.jpg',
      videoUrl: null,
      program: 'IoT',
    }
  ];

  // Achievements list
  const achievements = [
    'Global Awards 2022/23 ICT Training Service of the Year.',
    'Digital Public Service Award 2022 (SITA GovTech).',
    'HERA Award International Women.',
    'WIA 2021 Laurette Education Relevance.',
    'Auda-Nepad Digitization of Contents for 100,000 SMMEs.',
    'South African Small Business Award 2020 (Top 20 Award).',
    'Finalist Nedbank Business Ignite 2020.',
    'Roodepoort Chamber of Commerce & Industry (ROCCI) Awarded Business of the Year 2018/2019 in the Small, Medium Category.',
    'Under the MICT SETA Accreditation, we’ve trained ±1300 learners from underprivileged communities on various qualifications: System Support, Technical Support, Penetration Cyber Security (Work Skill Program), and Systems Development.',
    'We had the opportunity to deliver CompTIA CTT training for WHO (World Health Organization) in Uganda in June 2018 / Nov 2019.',
  ];

  // State to track the filtered stories (only IoT)
  const [filteredStories, setFilteredStories] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null); // Track which video is currently playing
  const videoRefs = useRef([]); // Create a reference array for video elements

  // Filter the success stories based on the selected program (only IoT)
  useEffect(() => {
    const stories = successStories.filter((story) => story.program === 'IoT');
    setFilteredStories(stories);
  }, []);

  // Handle hash navigation
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, []);

  // Handle video play logic
  const handleVideoPlay = (index) => {
    // Pause all videos before playing the selected one
    videoRefs.current.forEach((videoRef, i) => {
      if (i !== index) {
        videoRef.pause(); // Pause other videos
      }
    });

    const video = videoRefs.current[index];

    // If the same video is clicked again, stop it
    if (currentlyPlaying === index) {
      video.pause();
      setCurrentlyPlaying(null);
    } else {
      video.play();
      setCurrentlyPlaying(index); // Set the index of the currently playing video
    }
  };

  // Smooth scroll function
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="container mx-auto px-6 py-12">
      {/* Hero Section with Navigation Buttons */}
      <div className="flex flex-col items-center justify-center gap-4 mb-12">
        <h1 className="text-5xl font-bold text-center bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Our Success Journey
        </h1>
        <div className="flex flex-wrap justify-center gap-4 mt-6">
          <button
            onClick={() => scrollToSection('awards')}
            className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
          >
            Awards
          </button>
          <button
            onClick={() => scrollToSection('achievements-section')}
            className="px-6 py-3 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors"
          >
            Achievements
          </button>
          <button
            onClick={() => scrollToSection('iot-success-stories')}
            className="px-6 py-3 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors"
          >
            IoT Success Stories
          </button>
        </div>
      </div>

      {/* Awards and Achievements Section */}
      <div id="awards" className="mt-20">
        <h2 className="text-4xl font-bold text-center mb-8 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Awards & Achievements
        </h2>
        {/* EdTech Award Section */}
        <section id="edtech-award" className="bg-gradient-to-r from-green-600 to-blue-600 text-white py-16 mt-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold mb-10 flex items-center justify-center gap-3">
              <FaAward className="text-yellow-400" />
              Africa Tech Summit Awards 2025
            </h2>
            <p className="mb-4">Our Innovative EdTech Solutions have been awarded as one of the Best in Africa! 🎉</p>
            <p>📰 <a href="https://www.africatechsummit.com/nairobi/winners-announced-for-the-africa-tech-summit-awards-2025/" className="underline" target="_blank" rel="noopener noreferrer">Press Release</a></p>
            <EdTechWinnerSlide />
            <p>We're proud to be recognized for our contributions to EdTech innovation. Join us in shaping the future of education!</p>
          </div>
        </section>

        {/* Achievements Section */}
        <section id="achievements" className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-16 mt-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold mb-10 flex items-center justify-center gap-3">
              <FaTrophy className="text-yellow-400" />
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {achievements.map((achievement, index) => (
                <div key={index} className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <div className="text-gray-800 text-lg">
                    <FaAward className="text-blue-600 inline-block mr-2" />
                    {achievement}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      {/* IoT Success Stories Section */}
      <section id="iot-success-stories" className="mt-20">
        <h1 className="text-5xl font-bold text-center mb-12 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          IoT Success Stories
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredStories.length > 0 ? (
            filteredStories.map((story, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:scale-[1.02] border border-gray-100"
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-2">
                  <FaStar className="text-yellow-400" />
                  {story.title}
                </h2>

                <p className='mb-2'>{story.content}</p>

                {/* Media Display */}
                <div className="aspect-w-16 aspect-h-9 mb-6">
                  {story.videoUrl ? (
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      width="100%"
                      height="auto"
                      controls
                      className="rounded-md"
                      onPlay={() => handleVideoPlay(index)}
                    >
                      <source src={story.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={story.image}
                      alt={story.title}
                      className="w-full h-full object-cover rounded-md"
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No success stories available for IoT program yet.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SuccessStory;
