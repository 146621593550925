import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  FiClock,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { RiUserFill } from "react-icons/ri";
import SearchBar from "../components/SearchBar";
import axios from "axios";

const Blog = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const postsPerPage = 5;

  // Cache expiration time (1 hour in milliseconds)
  const CACHE_EXPIRATION_TIME = 60 * 60 * 1000;

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);

    // Check if data is in localStorage and still valid
    const cachedData = JSON.parse(localStorage.getItem('blogPosts'));
    const cacheTimestamp = localStorage.getItem('blogPostsTimestamp');
    
    // Add a check to see if cached data is still valid and if the data has changed on the server
    let shouldFetchFromServer = true;

    if (cachedData && cacheTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - cacheTimestamp < CACHE_EXPIRATION_TIME) {
        // Check if there is a newer version of the data on the server
        try {
          const response = await axios.head(
            "https://xpandcms.azurewebsites.net/api/content/67a07f64239c7c8af4a74235",
            {
              headers: {
                Authorization:
                  "Bearer 0bae8bbc32e2535ff2488a63a39ef14a2d6177efb2c3efe79fd46f092044112c",
                // Include the Last-Modified timestamp of cached data to check if it has changed
                'If-Modified-Since': new Date(parseInt(cacheTimestamp)).toUTCString(),
              },
            }
          );

          if (response.status === 304) {
            // If the status is 304, the data has not been modified, so use cached data
            setBlogPosts(cachedData);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          console.error("Error checking data modification:", error);
        }
      }
    }

    // Fetch from the server if data is not valid or has changed
    if (shouldFetchFromServer) {
      try {
        const response = await axios.get(
          "https://xpandcms.azurewebsites.net/api/content/67a07f64239c7c8af4a74235",
          {
            headers: {
              Authorization:
                "Bearer 0bae8bbc32e2535ff2488a63a39ef14a2d6177efb2c3efe79fd46f092044112c",
            },
          }
        );
        const data = response.data.post || [];
        setBlogPosts(data);

        // Cache the data and timestamp
        localStorage.setItem('blogPosts', JSON.stringify(data));
        localStorage.setItem('blogPostsTimestamp', new Date().getTime().toString());
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const filteredPosts = useMemo(() => {
    return blogPosts
      .filter(
        (post) =>
          post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (!selectedCategory || post.category?.includes(selectedCategory))
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [searchQuery, selectedCategory, blogPosts]);

  const currentPosts = useMemo(() => {
    const start = (currentPage - 1) * postsPerPage;
    return filteredPosts.slice(start, start + postsPerPage);
  }, [filteredPosts, currentPage]);

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const categories = useMemo(
    () => Array.from(new Set(blogPosts.flatMap((post) => post.category || []))),
    [blogPosts]
  );

  return (
    <div
      className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundImage: `url('/images/blog2.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold text-customBlue mb-8">
          Technology Made Simple: Insights and Solutions
        </h1>

        <SearchBar handleSearch={handleSearch} />

        <div className="mb-4">
          <label
            htmlFor="category"
            className="block text-sm font-medium text-gray-700"
          >
            Filter by Category:
          </label>
          <select
            id="category"
            name="category"
            className="block w-full py-2 pl-3 pr-10 border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={selectedCategory}
            onChange={(e) => handleCategoryFilter(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center space-x-2">
            <div className="w-4 h-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>
            <span className="text-gray-700">Loading...</span>
          </div>
        ) : filteredPosts.length === 0 ? (
          <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8 text-gray-800">
            <p>No blog posts found.</p>
          </div>
        ) : (
          currentPosts.map((post) => (
            <div
              key={post.id}
              className="bg-white rounded-lg shadow-md overflow-hidden mb-8 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <img
                src={post.featuredImage}
                alt={post.title}
                className="w-full h-64 object-cover rounded-t-lg"
                loading="lazy" // Lazy loading for images
              />
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                  <Link
                    to={`/blog/${post.slug}`}
                    className="hover:underline text-blue-600"
                  >
                    {post.title}
                  </Link>
                </h2>
                <div className="flex flex-auto font-thin text-sm">
                  <div className="flex items-center text-gray-600 mb-2 mr-3">
                    <FiClock className="mr-1" />
                    <span>
                      {new Date(post.createdAt)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .toUpperCase()}
                    </span>
                  </div>
                  <div className="flex items-center text-gray-600 mb-2">
                    <RiUserFill className="mr-1" />
                    <span>{post.username}</span>
                  </div>
                </div>
                <p
                  className="text-gray-800 mb-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      post.content.split(" ").slice(0, 20).join(" ") + "....",
                  }}
                ></p>

                <Link
                  to={`/blog/${post.slug}`}
                  className="text-blue-600 hover:underline"
                >
                  Continue Reading
                </Link>
              </div>
            </div>
          ))
        )}

        <div className="flex justify-center mt-8">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="px-3 py-1 border rounded-l-lg disabled:opacity-50"
          >
            <FiChevronsLeft />
          </button>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 border disabled:opacity-50"
          >
            <FiChevronLeft />
          </button>
          <span className="px-4 py-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border disabled:opacity-50"
          >
            <FiChevronRight />
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border rounded-r-lg disabled:opacity-50"
          >
            <FiChevronsRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blog;
