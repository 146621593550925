import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const [sliderSettings, setSliderSettings] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setSliderSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000, // 5 seconds interval between slides
        pauseOnHover: true,
        slidesToShow: screenWidth < 768 ? 1 : 3,
      });
    };

    handleResize(); // Set initial settings
    window.addEventListener("resize", handleResize); // Update settings on resize

    return () => window.removeEventListener("resize", handleResize); // Clean up event listener
  }, []);

  const testimonialData = [
    {
      id: 1,
      name: "Khensani Shipalane",
      position: "CEO, Life Of Purity PTY LTD",
      testimonial:
        "Ikusasa Technology Solutions has done excellent work in developing and hosting the Life Of Purity PTY LTD. They developed an online shopping system for us, which will assist in developing independent sales representatives throughout South Africa and advance the economy.",
    },
    {
      id: 2,
      name: "Refilwe Marumo",
      position: "Director, Mighty Comms",
      testimonial:
        "I would like to confirm that Mighty Comms has worked closely with Ikusasa Technology Solutions regarding learnership training services. We have found them to be professional in delivering their service of training learners in programs such as system and IT support, to name a few.",
    },
    {
      id: 3,
      name: "Papa Mkwane",
      position: "Managing Director, SIB Consulting",
      testimonial:
        "Ikusasa Technology Solutions has maintained an ongoing working relationship with SIB Consulting Pty Ltd. They have done exceptional work in designing and developing our Digital Platform.",
    },
    {
      id: 4,
      name: "Rudolf Malaka",
      position: "CEO, Dipatlile",
      testimonial:
        "Ikusasa has been a valued partner to Dipatlile, delivering exceptional training programs that have significantly enhanced our team's skills and productivity. Their expertise, enthusiasm, and commitment to excellence have made a profound impact on our organization. I highly recommend Ikusasa to any business seeking to upskill their workforce and drive growth.",
    },
    {
      id: 5,
      name: "Thabo Moalusi ",
      position: "Learner, System Support",
      testimonial:
        "The learnership has had an incredibly valuable experience for me. I have gained new skills, knowledge, and confidence that will undoubtedly benefit my future career. I appreciate the support and guidance provided by Ikusasa team",
    },
    // Add more testimonials as needed
  ];

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">
          Clients Testimonials
        </h2>
        <p className="text-center text-gray-600 mb-8">
          Hear directly from our clients about their experiences with us.
        </p>
        <Slider {...sliderSettings}>
          {testimonialData.map((testimonial) => (
            <div key={testimonial.id} className="px-2">
              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between h-full">
                <p className="text-gray-400 italic mb-4">
                  <FaQuoteLeft className="inline-block w-6 h-6 mr-2 text-custom-blue" />
                  {testimonial.testimonial}
                  <FaQuoteRight className="inline-block w-6 h-6 ml-2 text-custom-blue" />
                </p>
                <div className="text-center mt-auto">
                  <p className="font-semibold text-customRed mb-2">
                    {testimonial.name}
                  </p>
                  <p className="text-customBlue">{testimonial.position}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
