export const courses = [
  {
    originalName: "agriseta.html",
    displayName: "AGRISETA",
    imageUrl: "/images/agriseta.png",
    categories: ["Agriculture", "AGRISETA"],
  },
  {
    originalName: "alcatel.html",
    displayName: "ALCATEL",
    imageUrl: "/images/al.png",
    categories: [ "ALCATEL"],
  },
  {
    originalName: "comptiaa+.html",
    displayName: "COMPTIA A+",
    imageUrl: "/images/logoaplus.svg",
    categories: [ "CompTIA"],
  },
  {
    originalName: "comptiacourses.html",
    displayName: "COMPTIA CORE",
    imageUrl: "/images/comptiacore.png",
    categories: ["IT Certification", "CompTIA"],
  },
  {
    originalName: "cmptian+.html",
    displayName: "COMPTIA N+",
    imageUrl: "/images/logonetworkplus.svg",
    categories: ["CompTIA"],
  },
  {
    originalName: "comptiacybersec.html",
    displayName: "COMPTIA CYBERSECURITY",
    imageUrl: "/images/logocysaplus-01.svg",
    categories: ["CompTIA" , 'CYBERSECURITY'],
  },
  {
    originalName: "comptiafundamentals.html",
    displayName: "COMPTIA FUNDAMENTALS",
    imageUrl: "/images/logoitfundamentals.svg",
    categories: ["CompTIA"],
  },
  {
    originalName: "comptiainfrastructure.html",
    displayName: "COMPTIA INFRASTRUCTURE",
    imageUrl: "/images/comptiacore.png",
    categories: ["CompTIA"],
  },
  {
    originalName: "ibmdatapower.html",
    displayName: "IBM DATAPOWER",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmdb2c.html",
    displayName: "IBM DB2C",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmendp.html",
    displayName: "IBM ENDPOINT",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmguardium.html",
    displayName: "IBM GUARDIUM",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmi.html",
    displayName: "IBM I",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmitcam.html",
    displayName: "IBM ITCAM",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmblockchain.html",
    displayName: "IBM BLOCKCHAIN",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmpowersystem.html",
    displayName: "IBM POWER SYSTEM",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmsystemx.html",
    displayName: "IBM SYSTEM X",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmsystemstore.html",
    displayName: "IBM SYSTEM STORE",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmtrivovistore.html",
    displayName: "IBM TIVOLI STORE",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmwebsphereappserver.html",
    displayName: "IBM WEBSPHERE APP SERVER",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmwebspheremb.html",
    displayName: "IBM WEBSPHERE MB",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "ibmwebspheremq.html",
    displayName: "IBM WEBSPHERE MQ",
    imageUrl: "/images/ibm.png",
    categories: ["IBM"],
  },
  {
    originalName: "itil.html",
    displayName: "ITIL COURSES",
    imageUrl: "/images/itil.png",
    categories: ["ITIL"],
  },
  {
    originalName: "pecbcontinuity.html",
    displayName: "CONTINUITY",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "pecbcybersec.html",
    displayName: "CYBERSECURITY",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "pecbgovrisk.html",
    displayName: "GOVERNANCE RISK",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "pecbprivacy&data.html",
    displayName: "PRIVACY & DATA",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "pecbquality&manage.html",
    displayName: "QUALITY & MANAGEMENT",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "pecbsustanability.html",
    displayName: "SUSTAINABILITY",
    imageUrl: "/images/pecb.png",
    categories: ["PECB"],
  },
  {
    originalName: "popia.html",
    displayName: "POPIA COURSES",
    imageUrl: "/images/popia.png",
    categories: ["POPIA", "Legal Compliance"],
  },
  {
    originalName: "programdev.html",
    displayName: "PROGRAM DEVELOPMENT COURSES",
    imageUrl: "/images/pecb2.png",
    categories: ["Programming"],
  },
  {
    originalName: "certnexus.html",
    displayName: "CERTNEXUS COURSES",
    imageUrl: "/images/certnexus.png",
    categories: ["Certnexus"],
  },
  {
    originalName: "certnexusai.html",
    displayName: "CERTNEXUS AI",
    imageUrl: "/images/certnexus.png",
    categories: ["Certnexus"],
  },
  {
    originalName: "certnexuscybersec.html",
    displayName: "CERTNEXUS CYBERSECURITY",
    imageUrl: "/images/certnexus.png",
    categories: ["Certnexus"],
  },
  {
    originalName: "certnexusdatascience.html",
    displayName: "CERTNEXUS DATA SCIENCE",
    imageUrl: "/images/certnexus.png",
    categories: ["Certnexus"],
  },
  {
    originalName: "certnexusiot.html",
    displayName: "CERTNEXUS IOT",
    imageUrl: "/images/certnexus.png",
    categories: ["Certnexus"],
  },
  {
    originalName: "microsoft365.html",
    displayName: "MICROSOFT 365",
    imageUrl: "/images/Microsoft-365.webp",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftadmin.html",
    displayName: "MICROSOFT ADMIN",
    imageUrl: "/images/admin.webp",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftaiengineer.html",
    displayName: "AI ENGINEER",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftazurefundamentals.html",
    displayName: "AZURE FUNDAMENTALS",
    imageUrl: "/images/azure.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftCOURSES.html",
    displayName: "MICROSOFT COURSES",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftdataandai.html",
    displayName: "DATA & AI",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftDATAANAASS.html",
    displayName: "DATA ANALYTICS & ASSOCIATE",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftdatabaseadmin.html",
    displayName: "DATABASE ADMINISTRATION",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftDATAENGINEER.html",
    displayName: "DATA ENGINEER",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftdesktop.html",
    displayName: "MICROSOFT DESKTOP",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftdevop.html",
    displayName: "MICROSOFT DEVOPS",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftiotdev.html",
    displayName: "IOT DEVELOPMENT",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftmigrationwo.html",
    displayName: "MIGRATION WORKSHOP",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftmigrationwork.html",
    displayName: "MICROSOFT SAP WORKLOADS",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftsecengineer.html",
    displayName: "SECURITY ENGINEER",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftshap.html",
    displayName: "MICROSOFT SHAREPOINT",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftsolart.html",
    displayName: "MICROSOFT SOLAR TRAINING",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftsqlserver.html",
    displayName: "SQL SERVER",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftvs.html",
    displayName: "MICROSOFT VISUAL STUDIO",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "microsoftdevasso.html",
    displayName: "DEVELOPMENT ASSOCIATE",
    imageUrl: "/images/microsoft.png",
    categories: ["Microsoft"],
  },
  {
    originalName: "mictset.html",
    displayName: "MICT SETA COURSES",
    imageUrl: "/images/mict2.png",
    categories: ["MICT SETA"],
  },
  {
    originalName: "softskillsbusinesscourses.html",
    displayName: "BUSINESS COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillsinterpcourses.html",
    displayName: "INTERPERSONAL COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillsintrapcourses.html",
    displayName: "INTRAPERSONAL COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillssalesourses.html",
    displayName: "SALES COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillscustomercourses.html",
    displayName: "CUSTOMER COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillssupercourses.html",
    displayName: "SUPERVISORY COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillsbusiness.html",
    displayName: "SOFT SKILLS BUSINESS",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "softskillstfcourse.html",
    displayName: "SOFT SKILLS TF COURSES",
    imageUrl: "/images/soft skills.png",
    categories: ["Soft Skills"],
  },
  {
    originalName: "ciscoccna.html",
    displayName: "CCNA",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Networking"],
  },
  {
    originalName: "ciscoccnp.html",
    displayName: "CCNP",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Networking"],
  },
  {
    originalName: "ciscocybersec.html",
    displayName: "CYBERSECURITY",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Cybersecurity"],
  },
  {
    originalName: "ciscodatacience.html",
    displayName: "DATA SCIENCE",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Data Science"],
  },
  {
    originalName: "ciscofirepower.html",
    displayName: "FIREPOWER",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Security"],
  },
  {
    originalName: "ciscooperatingsys.html",
    displayName: "OPERATING SYSTEMS",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Operating Systems"],
  },
  {
    originalName: "ciscoprogramming.html",
    displayName: "PROGRAMMING",
    imageUrl: "/images/cisco3.png",
    categories: ["Cisco", "Programming"],
  },
  {
    originalName: "mictseta.html",
    displayName: "Website Development",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "Website Development"],
  },
  {
    originalName: "mictseta.html",
    displayName: "Infrastructure Development",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "Infrastructure Development"],
  },
 
  {
    originalName: "mictseta.html",
    displayName: "Application Development",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "Programming"],
  },
  {
    originalName: "mictseta.html",
    displayName: "System Support Development NQF Level 5",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "System Support"],
  },
  {
    originalName: "mictseta.html",
    displayName: "Technical Support NQF Level 4",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "Technical Support"],
  },
  
  {
    originalName: "mictseta.html",
    displayName: "System Development Programme NQF Level 5",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "System Development"],
  },
  {
    originalName: "mictseta.html",
    displayName: "End User Training",
    imageUrl: "/images/Mict Seta.png",
    categories: ["Mict Seta", "End User Training"],
  }
];
