import React from "react";
import { motion, useInView } from "framer-motion";
import { FaStar, FaUsers, FaAward, FaGraduationCap, FaUsersCog, FaAccessibleIcon, FaUniversalAccess } from 'react-icons/fa';


const Benefits = () => {
  const benefitsRef = React.useRef(null);
  const benefitsInView = useInView(benefitsRef, { once: true });

  return (
    <section ref={benefitsRef} className="py-5">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={benefitsInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6 }}
        className="container mx-auto px-4"
      >
        <h2 className="text-3xl font-bold text-center mb-4">Why Choose Us</h2>
        <div className="flex flex-wrap justify-around">
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaStar size={50} className="text-customGold mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Quality Training
              </h3>
              <p className="text-gray-600 mb-4">
                Trusted ICT training accredited by leading organizations.
                Internationally recognized qualifications. Address Skills
                Shortages.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaUsers size={50} className="text-customBrown mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Expert Team
              </h3>
              <p className="text-gray-600 mb-4">
                Dedicated team of professionals providing expert consulting and
                support services.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaAward size={50} className="text-green-500 mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Industry Recognition
              </h3>
              <p className="text-gray-600 mb-4">
                Recognized as a trusted reseller with competitive pricing and
                reliable IT solutions.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaGraduationCap size={50} className="text-purple-500 mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Accreditation
              </h3>
              <p className="text-gray-600 mb-4">
                Accredited training provider, ensuring programs meet required
                standards and comply with regulations.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaUsersCog size={50} className="text-red-500 mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Extensive Learnerships
              </h3>
              <p className="text-gray-600 mb-4">
              We offer ICT learnerships up to NQF Level 6, providing comprehensive training in specific fields
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4 text-center">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <FaUniversalAccess size={50} className="text-blue-500 mb-4 mx-auto" />
              <h3 className="text-2xl font-semibold text-customBlue mb-2">
                Accessibility
              </h3>
              <p className="text-gray-600 mb-4">
              We ensures active participation of learners with disabilities in our training programs, enabling businesses to prioritize training for employees with disabilities
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Benefits;
