import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partners = () => {
  const partnerData = [
    { id: 1, name: "Microsoft", logo: "/images/microsoft.png" },
    { id: 2, name: "Dell", logo: "/images/dell.png" },
    { id: 3, name: "Alcatel", logo: "/images/alcatel.png" },
    { id: 4, name: "CertNexus", logo: "/images/certnexus.png" },
    { id: 5, name: "ETDP", logo: "/images/etdp.png" },
    { id: 6, name: "MICT", logo: "/images/mict.png" },
    { id: 7, name: "PECB", logo: "/images/pecb.png" },
    { id: 8, name: "Proudly SA", logo: "/images/ProudlySA_Member_Logo 2.png" },
    { id: 9, name: "Cisco", logo: "/images/Cisco2.png" },
    { id: 10, name: "Hp", logo: "/images/hp2.png" },
    // Add more partners as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000, // Speed of the slide transition in milliseconds
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Adjusted speed for autoplay in milliseconds
    cssEase: 'linear',
    pauseOnHover: true, // Enable pausing on hover
    centerMode: false, // Disable center mode for consistent spacing
    responsive: [
      {
        breakpoint: 1024, // For tablets and desktops
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480, // For very small screens
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Our <span className="text-customRed">Trusted</span> Partners
        </h2>

        <Slider {...settings} className="partner-carousel">
          {partnerData.map((partner) => (
            <div
              key={partner.id}
              className="flex justify-center px-4" // Adjusted padding for spacing
            >
              <div className="flex items-center justify-center">
                <img
                  src={partner.logo}
                  alt={`${partner.name} logo`}
                  className="h-20 w-auto mx-auto" // Ensure logos are centered and maintain aspect ratio
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Partners;
