import React, { useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import Confetti from 'react-confetti';
import { FaTrophy } from 'react-icons/fa';
import { motion } from 'framer-motion';

const slideImages = [
  {
    url: 'images/AfriTechAwards.jpg',
    caption: 'EdTech Winner',
    description: 'Our innovative EdTech solutions have been officially recognized as the Winner of the EdTech Award at the 2025 African Tech Summit!',
    link: '/success#awards',
    isCelebratory: true,
  },
  {
    url: 'https://res.cloudinary.com/dgjzyis2j/image/upload/v1738565744/pmauqmy9ufiaouuu2ghq.jpg',
    caption: 'Training',
    description: 'Stay Relevant with Market-Demanded Skills',
    link: '/training',
    additionalInfo: '4000+ trained youth, 85% placement rate',
  },
  {
    url: 'images/c6.jpeg',
    caption: 'Consulting',
    description: 'Expert Consulting for Strategic Growth',
    link: '/consulting',
  },
  {
    url: 'images/reseller3.jpeg',
    caption: 'Reseller',
    description: 'Comprehensive IT Solutions and Trusted Reseller Services',
    link: '/reseller',
  },
  {
    url: 'images/gal21.jpg',
    caption: 'IoT Training Program Achievement',
    description: 'We Congratulate IoT learners on Completing the IoT Program with a 100% Pass Rate!',
    link: '/success#iot-success-stories',
    isCelebratory: true,
  },
];

const HeroSection = () => {
  const [trainedCount, setTrainedCount] = useState(0);
  const [placementRate, setPlacementRate] = useState(0);
  const [startTouchY, setStartTouchY] = useState(null);
  const [startTouchX, setStartTouchX] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const trainedInterval = setInterval(() => {
      if (trainedCount < 4000) {
        setTrainedCount((prevCount) => prevCount + 250);
      } else {
        clearInterval(trainedInterval);
      }
    }, 80);

    const placementInterval = setInterval(() => {
      if (placementRate < 85) {
        setPlacementRate((prevRate) => prevRate + 5);
      } else {
        clearInterval(placementInterval);
      }
    }, 80);

    return () => {
      clearInterval(trainedInterval);
      clearInterval(placementInterval);
    };
  }, [trainedCount, placementRate]);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartTouchY(touch.clientY);
    setStartTouchX(touch.clientX);
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const endTouchY = touch.clientY;
    const endTouchX = touch.clientX;

    const verticalSwipeThreshold = 50;
    const horizontalSwipeThreshold = 50;

    const diffY = endTouchY - startTouchY;
    const diffX = endTouchX - startTouchX;

    if (Math.abs(diffY) > verticalSwipeThreshold && Math.abs(diffX) < horizontalSwipeThreshold) {
      if (diffY < 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slideImages.length);
      } else {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slideImages.length) % slideImages.length);
      }
    }
  };

  return (
    <div className="relative bg-gray-50" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <Slide
        arrows={true}
        indicators={true}
        autoplay={true}
        pauseOnHover={false}
        duration={5000}
        transitionDuration={0}
        defaultIndex={0}
        prevArrow={
          <IoIosArrowBack className="absolute left-0 arrow prev text-white text-4xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-gray-800 transition duration-300" />
        }
        nextArrow={
          <IoIosArrowForward className="absolute right-0 arrow next text-white text-4xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-gray-800 transition duration-300" />
        }
        className="slide-container"
        index={currentIndex}
      >
        {slideImages.map((slide, index) => (
          <div
            key={index}
            className="relative min-h-screen flex items-center justify-center py-8 px-4"
            style={{
              backgroundImage: `url(${slide.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {slide.caption === 'EdTech Winner' && (
              <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70 flex items-center justify-center text-white">
                <div className="text-center animate-fade-in-up">
                  <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-yellow-500 text-black px-6 py-2 rounded-full shadow-lg font-bold text-sm uppercase">
                    {slide.ribbonText}
                  </div>
                  <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 animate-slide-in-left">
                    Proud Winners of the 2025 African Tech Summit EdTech Award!
                  </h2>
                  <p className="text-lg md:text-xl max-w-2xl mx-auto mb-8 animate-slide-in-right">
                    {slide.description}
                  </p>

                  <img src="/images/EdTechBadge.jpg" alt="EdTech Badge" className="left-1/2 w-36 h-auto mx-auto" />
                  <div className="mt-6 animate-fade-in">
                    <Link to={slide.link}>
                      <button className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-6 py-3 md:px-8 md:py-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 text-base md:text-lg font-semibold">
                        View Award Details
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {slide.caption !== 'EdTech Winner' && (
              <div className="absolute inset-0 bg-black bg-opacity-55 flex items-center justify-center text-white">
                <div className="text-center">
                  <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold">{slide.caption}</h2>
                  <p className="text-base md:text-lg">{slide.description}</p>
                  {slide.additionalInfo && slide.caption === 'Training' && (
                    <div>
                      <p className="text-md mt-2 flex items-center justify-center">
                        <span className="font-extrabold text-3xl mr-2">{`${trainedCount}+`}</span> Trained Youth
                      </p>
                      <p className="text-md flex items-center justify-center">
                        <span className="font-extrabold text-3xl mr-2">{`${placementRate}%`}</span> Placement Rate
                      </p>
                    </div>
                  )}

                  {slide.isCelebratory && slide.caption !== 'EdTech Winner' && (
                    <div className="mt-4 text-3xl font-bold text-yellow-400">
                      <p className="text-center text-3xl md:text-4xl lg:text-4xl font-extrabold text-white">🎉 Congratulations! 🎉</p>
                      <p className="text-center text-xl md:text-2xl lg:text-3xl font-extrabold">100% Pass Rate - You Did It!</p>
                      <div className="mt-4">
                        <Link to={slide.link}>
                          <button className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-full shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 text-sm sm:text-base md:text-lg">

                            View Success Stories
                          </button>
                        </Link>
                      </div>
                      <div className="celebration-icons mt-4">
                        <FaTrophy className="w-16 h-16 mx-auto text-yellow-500" />
                      </div>
                    </div>
                  )}

                  {!slide.isCelebratory && (
                    <div className="mt-4">
                      <Link to={`services${slide.link}`}>
                        <button className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-3 py-1 md:px-4 md:py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105 text-sm md:text-base">
                          Read More
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
            {slide.isCelebratory && <Confetti width={window.innerWidth} height={window.innerHeight} />}
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default HeroSection;
