import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const EdTechWinnerSlide = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  const images = [
    '/images/AfriTechAwards.jpg',
    '/images/EdTechWinner.jpg',
    '/images/EdTechWinnersGroup.jpg'
    // Add more image paths here
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img 
              src={image} 
              alt={`Award Ceremony ${index + 1}`}
              className="rounded-lg shadow-xl w-full h-96 object-cover"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EdTechWinnerSlide;
