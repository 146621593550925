export const dellProducts = [
  {
    id: "monitor",
    imgSrc: "/images/Monitor.avif",
    imgSrc2: "/images/monitor2.webp",
    altText: "Dell Monitor",
    title: "Monitors",
    description: "Dell E2724HS 27-inch Full HD 5ms LED Monitor",
    specifications: [
      {
        fieldName: "Screen Size",
        options: [
          { name: "Select Screen Size" },
          { name: "24 inches" },
          { name: "27 inches" },
          { name: "32 inches" },
          { name: "34 inches" },
          { name: "38 inches" },
          { name: "42 inches" },
        ],
      },
      { fieldName: "Resolution" },
      { fieldName: "Refresh Rate" },
      { fieldName: "Ports" },
    ],
  },
  {
    id: "bag",
    imgSrc: "/images/bag.webp",
    imgSrc2: "/images/bag2.webp",
    altText: "Dell Laptop Bag",
    title: "Laptop Bags",
    description: "Dell EcoLoop Urban 15-inch Notebook Backpack 460-BDLF.",
    specifications: [

      { fieldName: "Color",     options: [
        { name: "Select Color" },
        { name: "Black" },
        { name: "Gray" },
        { name: "Blue" },
     
      ], },
  
    ],
  },
  {
    id: "laptop",
    imgSrc: "/images/laptop.webp",
    imgSrc2: "/images/laptop2.webp",


    altText: "Dell Laptop",
    title: "Laptops",
    description: "Dell Inspiron 3520 15.6-inch Core i7-1255U 16GB RAM 512GB SSD Win 11 Home Laptop",
    specifications: [
      {
        fieldName: "Processor",
        options: [
          { name: "Select Processor" },
          { name: "Intel Core i3" },
          { name: "Intel Core i5" },
          { name: "Intel Core i7" },
          { name: "Intel Core i9" },
        ],
      },
      {
        fieldName: "RAM",
        options: [
          { name: "Select Ram" },
          { name: "4GB" },
          { name: "8GB" },
          { name: "16GB" },
          { name: "32GB" },
          { name: "64GB" },
        ],
      },
      {
        fieldName: "Storage",
        options: [
          { name: "Select Storage" },
          { name: "512GB SSD" },
          { name: "1TB HDD" },
          { name: "2TB HDD" },
        ],
      },
      {
        fieldName: "Screen Size",
        options: [
          { name: "Select Screen Size" },
          { name: "13.3 inches" },
          { name: "15.6 inches" },
          { name: "17.3 inches" },
        ],
      },
      {
        fieldName: "Graphics Card",
        options: [
          { name: "Select Graphics Card" },
          { name: "Intel Integrated Graphics" },
          { name: "NVIDIA GeForce GTX 1650" },
          { name: "NVIDIA GeForce RTX 3060" },
          { name: "AMD Radeon RX 5600M" },
          { name: "NVIDIA GeForce GT 710" },
          { name: "AMD Radeon R5 235X" },
        ],
      },
      {
        fieldName: "Operating System",
        options: [
          { name: "Select Operating System" },
          { name: "Windows 10" },
          { name: "Windows 11" },
        ],
      },
      { fieldName: "Battery Life" },
      { fieldName: "Weight" },
      { fieldName: "Ports" },
    ],
  },
  {
    id: "chargers",
    imgSrc: "/images/charger.webp",
    imgSrc2: "/images/charger2.webp",
    altText: "Dell Charger",
    title: "Charger",
    description: "Dell 65W 4.5mm Barrel Notebook Charger with Power Cord 450-AECN",
    specifications: [
      { fieldName: "Compatibility" },
      { fieldName: "Output" },
      { fieldName: "Connector Type" },
      { fieldName: "Cable Length" },
    ],
  },
  {
    id: "desktop",
    imgSrc: "/images/desktop.webp",
    imgSrc2: "/images/desktop2.webp",
    altText: "Dell Desktop",
    title: "Desktop",
    description: "Dell Vostro 3910 Core i7-12700 8GB RAM 1TB HDD Win 11 Pro Midi Tower N7560VDT3910EMEA01",
    specifications: [
      {
        fieldName: "Processor",
        options: [
          { name: "Select Processor" },
          "Intel Core i5",
          "Intel Core i7",
          "AMD Ryzen 5",
          "AMD Ryzen 7",
        ],
      },
      {
        fieldName: "RAM",
        options: [
          { name: "Select Ram" },
          "4GB",
          "8GB",
          "16GB",
          "32GB",
          "64GB",
        ],
      },
      {
        fieldName: "Storage",
        options: [
          { name: "Select Storage" },
          { name: "512GB SSD", type: "SSD" },
          { name: "1TB HDD", type: "HDD" },
          { name: "2TB HDD", type: "HDD" },
        ],
      },
      {
        fieldName: "Graphics Card",
        options: [
          { name: "Select Graphics Card" },
          { name: "NVIDIA GeForce GTX 1660", size: "6GB" },
          { name: "NVIDIA GeForce RTX 3070", size: "8GB" },
          { name: "AMD Radeon RX 6800 XT", size: "16GB" },
        ],
      },
      {
        fieldName: "Operating System",
        options: [
          { name: "Select Operating System" },
          { name: "Windows 10" },
          { name: "Windows 11" },
     
        ],
      },
      { fieldName: "Dimensions" },
      { fieldName: "Ports" },
    ],
  },
  {
    id: "keyboard-mouse",
    imgSrc: "/images/keyandmouse.webp",
    imgSrc2: "/images/keyandmouse2.webp",
    altText: "Dell Keyboards and Mouse",
    title: "Keyboards and Mouse",
    description: "Dell KM3322W Wireless Keyboard and Mouse Black",
    specifications: [
      { fieldName: "Type" },
      { fieldName: "Connectivity" },
      { fieldName: "Compatibility" },
      { fieldName: "Features" },
    ],
  },
  
  {
    id: "battery",
    imgSrc: "/images/bat.webp",
    imgSrc2: "/images/bat2.webp",
    altText: "Dell Battery",
    title: "Batteries",
    description: "Dell 40WHr 4-Cell Primary Lithium-Ion Notebook Battery 453-BBBR",
    specifications: [
      { fieldName: "Type" },
      { fieldName: "Capacity" },
      { fieldName: "Compatibility" },
      { fieldName: "Cell Type" },
    ],
  }
];
