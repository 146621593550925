import React from "react";
import { FaDownload } from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

const PdfViewer = () => {
  return (
    <>
      <div className="bg-gray-900 flex items-center justify-end p-1 text-white hover:text-gray-400">
        <a
          href="ITS-Training-Schedule-Jan-Jun-2025.pdf"
          download="ITS-Training-Schedule-Jan-Jun-2025.pdf"
          className="transition-colors duration-300"
        >
          <FaDownload className="text-3xl w-5 mr-10" />
        </a>
      </div>
      <div className="relative w-full min-h-screen sm:h-[90vh] border border-gray-200 overflow-hidden">
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl="ITS-Training-Schedule-Jan-Jun-2025.pdf" />
        </Worker>
      </div>
    </>
  );
};

export default PdfViewer;
