import React, { useState } from 'react';

const SearchBar = ({ handleSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    handleSearch(inputValue); // Call handleSearch function from parent component
  };

  return (
    <form className="mb-4">
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Search blog posts..."
        className="px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-blue-500"
      />
    </form>
  );
};

export default SearchBar;
