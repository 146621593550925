import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { dellProducts } from "../data/dellProducts";
import { licences } from "../data/microsoftLicenses";
import { QuoteModal } from "../components/QuoteModal";
import { useDispatch } from "react-redux";
import { setSelectedService } from "../slices/trainingSlice";
import { useSelector } from "react-redux";
import {
  Code,
  Wrench,
  ShieldCheck,
  Server,
  Cloud,
  Laptop,
  Bot,
  BarChart3,
  Scale,
  ChevronRight
} from "lucide-react";

// Service card component for training services
const TrainingCard = ({ image, title, description, linkTo, onClick, delay }) => (
  <div
    className="service-card bg-white rounded-xl shadow-soft overflow-hidden hover:shadow-card group transition-all duration-300 transform hover:-translate-y-2"
    style={{ animationDelay: `${delay * 0.1}s` }}
  >
    <div className="card-img-container h-48 overflow-hidden relative">
      <img
        className="w-full h-full object-cover object-center transition-transform duration-500 group-hover:scale-110"
        src={image}
        alt={title}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    </div>
    <div className="p-6">
      <h3 className="text-xl font-bold text-service-text mb-2 group-hover:text-customRed transition-colors duration-300">{title}</h3>
      <p className="text-service-lightText mb-4 min-h-[80px]">{description}</p>
      <Link
        to={linkTo}
        onClick={onClick}
        className="cta-button inline-flex items-center justify-center gap-2 bg-customRed hover:bg-customBlue text-white font-medium px-5 py-2 rounded-full shadow-button transition-all duration-300"
      >
        <span>View Courses</span>
        <ChevronRight className="w-4 h-4" />
      </Link>
    </div>
  </div>
);

// Service card component for consulting services
const ConsultingCard = ({ Icon, title, description, linkTo, delay }) => (
  <div
    className="service-card h-full fade-in"
    style={{ animationDelay: `${delay * 0.1}s` }}
  >
    <Link to={linkTo}>
      <div className="p-6 flex flex-col items-center justify-center bg-white shadow-soft rounded-xl h-full transition-all duration-300 group hover:shadow-lg hover:border-customBlue border-2 border-transparent">
        <div className="service-icon p-4 mb-5 bg-customRed bg-opacity-10 rounded-full text-customRed group-hover:bg-customBlue group-hover:text-white group-hover:bg-opacity-100 transition-all duration-300 transform group-hover:scale-110">
          <Icon className="h-8 w-8" />
        </div>
        <h3 className="text-xl font-bold text-service-text mb-3 group-hover:text-customBlue transition-colors duration-300">
          {title}
        </h3>
        <p className="text-service-lightText text-center mb-4">{description}</p>
        <div className="mt-auto">
          <Link
            to={linkTo}
            className="learn-more text-customRed hover:text-customBlue transition-colors duration-300 flex items-center font-medium"
          >
            Learn more <span className="ml-1 transition-transform duration-300 transform group-hover:translate-x-2">→</span>
          </Link>
        </div>
      </div>
    </Link>
  </div>
);

// Product card component for reseller products
const ProductCard = ({ product, hoverState, onMouseEnter, onMouseLeave, onQuoteClick, delay }) => (
  <div
    className="service-card scale-in transform transition-all duration-300 hover:-translate-y-2"
    style={{ animationDelay: `${delay * 0.1}s` }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className="flex flex-col h-full bg-white rounded-xl shadow-soft overflow-hidden hover:shadow-lg">
      <div className="flex justify-center items-center p-6 bg-gray-50">
        <img
          src={hoverState ? product.imgSrc2 : product.imgSrc}
          alt={product.altText}
          className="w-[60%] h-[120px] object-contain transition-all duration-500"
        />
      </div>
      <div className="px-6 py-4 flex-grow border-t border-gray-100">
        <h4 className="text-xl font-bold text-customRed mb-3">
          {product.title}
        </h4>
        <p className="text-service-lightText">
          {product.description}
        </p>
      </div>
      <div className="px-6 py-4 mt-auto">
        <button
          onClick={() => onQuoteClick(product)}
          className="cta-button w-full bg-customRed hover:bg-customBlue text-white font-medium px-5 py-2 rounded-full transition-all duration-300 flex items-center justify-center gap-2"
        >
          <span>Get Quote</span>
          <ChevronRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
);

// License card component
const LicenseCard = ({ imgSrc, altText, title, description, onClick, delay }) => (
  <div
    className="service-card scale-in transform transition-all duration-300 hover:-translate-y-2"
    style={{ animationDelay: `${delay * 0.1}s` }}
  >
    <div className="bg-white rounded-xl shadow-soft overflow-hidden hover:shadow-lg h-full flex flex-col">
      <div className="relative h-48 card-img-container overflow-hidden">
        <img
          src={imgSrc}
          alt={altText}
          className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 hover:scale-110"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent"></div>
        <h4 className="absolute bottom-4 left-4 text-xl font-semibold text-white">{title}</h4>
      </div>
      <div className="px-6 py-4 flex-grow">
        {description && <p className="text-service-lightText">{description}</p>}
      </div>
      <div className="px-6 py-4 mt-auto">
        <button
          onClick={onClick}
          className="cta-button w-full bg-customRed hover:bg-customBlue text-white font-medium px-5 py-2 rounded-full transition-all duration-300 flex items-center justify-center gap-2"
        >
          <span>Get Quote</span>
          <ChevronRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
);

// Section component
const ServiceSection = ({ id, title, children, bg = "bg-service-background" }) => (
  <section
    id={id}
    className={`py-20 ${bg}`}
  >
    <div className="section-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="section-title text-4xl font-bold text-service-text mb-16 text-center relative">
        {title}
        <span className="block h-1.5 w-24 bg-customRed mx-auto mt-4 rounded-full"></span>
      </h2>
      {children}
    </div>
  </section>
);

// Hero section for services page
const ServicesHero = () => (
  <div className="bg-gradient-to-r from-customRed to-customBlue text-white py-24 relative overflow-hidden">
    <div className="absolute inset-0 opacity-10">
      <div className="absolute top-0 left-0 w-64 h-64 bg-white rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-white rounded-full blur-3xl transform translate-x-1/2 translate-y-1/2"></div>
    </div>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-6">Our Services</h1>
        <p className="text-xl max-w-3xl mx-auto opacity-90">
          Comprehensive IT solutions tailored to meet your business needs, from training and consulting to hardware and software solutions.
        </p>
      </div>
    </div>
  </div>
);

const Services = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hoverStates, setHoverStates] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();

  const handleTrainingClick = (service) => {
    dispatch(setSelectedService(service));
  };

  const handleGetQuoteClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) => ({ ...prevStates, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) => ({ ...prevStates, [index]: false }));
  };

  useEffect(() => {
    const path = location.pathname;
    const parts = path.split("/");
    const serviceId = parts.length > 2 ? parts[2] : "";

    if (serviceId) {
      const element = document.getElementById(serviceId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 300);
      }
    } else {
      window.scrollTo(0, 0);
    }

    // Add fade-in and scale-in animations to cards
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
      }
      
      @keyframes scaleIn {
        from { opacity: 0; transform: scale(0.9); }
        to { opacity: 1; transform: scale(1); }
      }
      
      .fade-in {
        opacity: 0;
        animation: fadeIn 0.6s ease-out forwards;
      }
      
      .scale-in {
        opacity: 0;
        animation: scaleIn 0.6s ease-out forwards;
      }
      
      .shadow-soft {
        box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.05), 0 8px 10px -6px rgba(0, 0, 0, 0.02);
      }
      
      .shadow-card {
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      }
      
      .shadow-button {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [location.pathname]);

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Training Services Section */}
      <ServiceSection id="training" title="Training Services">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Microsoft Card */}
          <TrainingCard
            image="/images/micro.jpeg"
            title="Microsoft"
            description="Master Office 365 to Azure with our tailored Microsoft training programs."
            linkTo="/courses/microsoft"
            onClick={() => handleTrainingClick("Microsoft")}
            delay={0}
          />

          {/* CompTIA Card */}
          <TrainingCard
            image="/images/comp.jpeg"
            title="CompTIA"
            description="Prepare for globally recognized A+, Network+, and Security+ certifications with our expert-led courses."
            linkTo="/courses/comptia"
            onClick={() => handleTrainingClick("CompTIA")}
            delay={1}
          />

          {/* Cisco Card */}
          <TrainingCard
            image="/images/csc.jpeg"
            title="Cisco"
            description="Specialize in Cisco networking solutions through our focused training modules."
            linkTo="/courses"
            onClick={() => handleTrainingClick("Cisco")}
            delay={2}
          />

          {/* Kids Code Academy Card */}
          <TrainingCard
            image="/images/kid.jpeg"
            title="Kids Code Academy"
            description="We teach kids how to code from an early age."
            linkTo="/kidscoding"
            onClick={() => { }}
            delay={3}
          />

          {/* Soft Skills Card */}
          <TrainingCard
            image="/images/sskill.jpeg"
            title="Soft Skills"
            description="Develop essential soft skills like communication, leadership, and teamwork."
            linkTo="/courses/soft-skills"
            onClick={() => handleTrainingClick("Soft Skills")}
            delay={4}
          />

          {/* Learnerships Card */}
          <TrainingCard
            image="/images/grad.jpeg"
            title="Learnerships"
            description="Join SETA accredited and structured programs blend practical and theoretical learning."
            linkTo="/careers"
            onClick={() => { }}
            delay={5}
          />
        </div>

        {/* View All Courses CTA Button */}
        <div className="text-center mt-12">
          <Link
            to="/courses"
            className="primary-btn inline-flex items-center justify-center gap-2 bg-customRed hover:bg-customBlue text-white font-medium px-6 py-3 rounded-full shadow-button transition-all duration-300"
          >
            <span>View All Courses</span>
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
      </ServiceSection>

      {/* Consulting Services Section */}
      <ServiceSection id="consulting" title="Consulting Services" bg="bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Software Development */}
          <ConsultingCard
            Icon={Code}
            title="Software Development"
            description="End-to-end services from concept to deployment."
            linkTo="consulting/software"
            delay={0}
          />

          {/* Support & Maintenance */}
          <ConsultingCard
            Icon={Wrench}
            title="Support & Maintenance"
            description="Ensuring high uptime for seamless and efficient IT system operations."
            linkTo="consulting/support"
            delay={1}
          />

          {/* Cybersecurity */}
          <ConsultingCard
            Icon={ShieldCheck}
            title="Cybersecurity"
            description="Implementing robust measures to protect your business."
            linkTo="consulting/cybersecurity"
            delay={2}
          />

          {/* Cloud Solutions */}
          <ConsultingCard
            Icon={Cloud}
            title="Cloud Solutions"
            description="Utilizing cloud platforms for scalability, efficiency, and cost-effectiveness."
            linkTo="consulting/cloud"
            delay={3}
          />

          {/* Digital Transformation */}
          <ConsultingCard
            Icon={Laptop}
            title="Digital Transformation"
            description="Enhancing operations, customer experiences, and fostering innovation."
            linkTo="consulting/transformation"
            delay={4}
          />

          {/* Hosting */}
          <ConsultingCard
            Icon={Server}
            title="Hosting"
            description="Reliable hosting solutions for secure and accessible websites."
            linkTo="consulting/hosting"
            delay={5}
          />

          {/* AI and Machine Learning */}
          <ConsultingCard
            Icon={Bot}
            title="AI and Machine Learning"
            description="Using AI and machine learning for innovation and competitive advantage."
            linkTo="consulting/ai-ml"
            delay={6}
          />

          {/* Compliance and Regulations */}
          <ConsultingCard
            Icon={Scale}
            title="Compliance and Regulations"
            description="Ensuring compliance and navigating regulatory landscapes for businesses."
            linkTo="consulting/compliance"
            delay={7}
          />

          {/* Data Analytics */}
          <ConsultingCard
            Icon={BarChart3}
            title="Data Analytics"
            description="Utilizing data-driven insights to optimize business strategies and decision-making."
            linkTo="consulting/data"
            delay={8}
          />
        </div>

        <div className="mt-12 text-center">
          <Link
            to="/contact"
            className="primary-btn inline-flex items-center justify-center gap-2 bg-customRed hover:bg-customBlue text-white font-medium px-6 py-3 rounded-full shadow-button transition-all duration-300"
          >
            <span>Partner with us</span>
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
      </ServiceSection>

      {/* Reseller Services Section */}
      <ServiceSection id="reseller" title="Reseller Services">
        <div className="mb-16">
          <h3 className="text-2xl font-semibold text-service-text mb-10 text-center relative">
            Featured Dell Products
            <span className="block h-1 w-16 bg-customRed mx-auto mt-3 rounded-full"></span>
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {dellProducts &&
              dellProducts.map((product, index) => (
                <ProductCard
                  key={index}
                  product={product}
                  hoverState={hoverStates[index]}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onQuoteClick={handleGetQuoteClick}
                  delay={index}
                />
              ))}
          </div>
        </div>

        <div className="mt-20">
          <h3 className="text-2xl font-semibold text-service-text mb-10 text-center relative">
            Microsoft Licenses and Exam Vouchers
            <span className="block h-1 w-16 bg-customRed mx-auto mt-3 rounded-full"></span>
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {licences &&
              licences.map((product, index) => (
                <LicenseCard
                  key={index}
                  imgSrc={product.imgSrc}
                  altText={product.altText}
                  title={product.title}
                  description={product.description}
                  onClick={() => handleGetQuoteClick(product)}
                  delay={index}
                />
              ))}
          </div>
        </div>
      </ServiceSection>

      {/* Quote Modal */}
      {isModalOpen && (
        <QuoteModal
          className="z-50"
          product={selectedProduct}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Services;