import React, { useState } from "react";
import {
  FiArrowDown,
  FiSearch,
  FiMapPin,
  FiBriefcase,
  FiArrowLeft,
  FiUser,
  FiMail,
  FiPhone,
} from "react-icons/fi";
import { jobListings } from "../data/joblisting";
import axios from "axios";
import HeroSection from "../components/CareerHeroSection";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import { FaPaperPlane } from "react-icons/fa";

const Career = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState("");
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    cv: null,
    acceptTerms: false,
  });
  const [formStatus, setFormStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for loading

  const locations = [...new Set(jobListings.map((job) => job.location))];
  const jobTypes = [...new Set(jobListings.map((job) => job.jobType))];

  const filteredJobs = jobListings.filter(
    (job) =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (locationFilter === "" || job.location === locationFilter) &&
      (jobTypeFilter === "" || job.jobType === jobTypeFilter)
  );

  const handleJobSelect = (job) => setSelectedJob(job);
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleLocationChange = (e) => setLocationFilter(e.target.value);
  const handleJobTypeChange = (e) => setJobTypeFilter(e.target.value);
  const handleBackToList = () => setSelectedJob(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target.result.split(",")[1];
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          cv: {
            fileContent,
            fileName: file.name,
            fileType: file.type,
          },
        }));
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading
    try {
      const response = await axios.post(
        "https://ikusasasolutions.co.za/career.php",
        userDetails,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);

      Swal.fire({
        title: "Thank You!",
        text: "Your CV has been submitted successfully",
        icon: "success",
        confirmButtonText: "Ok",
      });

      setFormStatus("Form submitted successfully!");
      setIsSubmitting(false)

      setUserDetails({
        fullName: "",
        email: "",
        phone: "",
        address: "",
        cv: null,
        acceptTerms: false,
      });
    } catch (error) {
      console.error("Error uploading file:", error);

      Swal.fire({
        title: "Error!",
        text: "There was an error submitting your CV. Please try again or contact info@ikusasatech.com for assistance.",
        icon: "error",
        confirmButtonText: "Ok",
      });

      setFormStatus("Error submitting form. Please try again.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-8">
      <HeroSection />
      <div
        id="opportunities"
        className="w-full max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden mt-8"
      >
        {!selectedJob ? (
          <div className="p-6">
            <h2 className="text-3xl font-bold mb-6 text-center text-customBlue">
              Career Opportunities
            </h2>
            <div className="flex flex-col sm:flex-row sm:space-x-4 mb-6">
              <div className="relative w-full sm:w-1/3 mb-4 sm:mb-0">
                <FiSearch className="absolute top-2 left-2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search for a job..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                />
              </div>
              <div className="relative w-full sm:w-1/3 mb-4 sm:mb-0">
                <FiMapPin className="absolute top-2 left-2 text-gray-400" />
                <select
                  value={locationFilter}
                  onChange={handleLocationChange}
                  className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                >
                  <option value="">All Locations</option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="relative w-full sm:w-1/3">
                <FiBriefcase className="absolute top-2 left-2 text-gray-400" />
                <select
                  value={jobTypeFilter}
                  onChange={handleJobTypeChange}
                  className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                >
                  <option value="">All Job Types</option>
                  {jobTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {filteredJobs.length > 0 ? (
              <ul className="divide-y divide-gray-200">
                {filteredJobs.map((job) => (
                  <li
                    key={job.id}
                    className="py-4 cursor-pointer hover:bg-gray-100 transition-colors"
                    onClick={() => handleJobSelect(job)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <h4 className="text-lg font-semibold text-customBlue">
                          {job.title}
                        </h4>
                        <p className="text-gray-500">{job.location}</p>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 1 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="text-gray-600 mt-2">{job.description}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center text-gray-500">
                <p>No job listings available.</p>
                <p>Please leave your details and CV for future vacancies:</p>
                <form onSubmit={handleSubmit} className="mt-4 space-y-6">
                  <div className="flex flex-col sm:flex-row sm:space-x-4">
                    <div className="relative w-full sm:w-1/2">
                      <FiUser className="absolute top-2 left-2 text-gray-400" />
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Full Name"
                        value={userDetails.fullName}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                        required
                      />
                    </div>
                    <div className="relative w-full sm:w-1/2">
                      <FiMail className="absolute top-2 left-2 text-gray-400" />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={userDetails.email}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                        required
                      />
                    </div>
                  </div>
                  <div className="relative w-full">
                    <FiPhone className="absolute top-2 left-2 text-gray-400" />
                    <input
                      type="text"
                      name="phone"
                      required
                      placeholder="Phone Number"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                    />
                  </div>
                  <div className="relative w-full">
                    <FiMapPin className="absolute top-2 left-2 text-gray-400" />
                    <input
                      type="text"
                      name="address"
                      required
                      placeholder="Address"
                      value={userDetails.address}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2 pl-8"
                    />
                  </div>
                  <div className="relative w-full">
                    <label className="block text-gray-700 mb-2">Upload CV</label>
                    <input
                      type="file"
                      required
                      onChange={handleFileChange}
                      className="border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
                      accept=".pdf,.doc,.docx"
                    />
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      checked={userDetails.acceptTerms}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                      required
                    />
                    <label htmlFor="acceptTerms" className="text-gray-600">
                      I accept the{" "}
                      <a
                        href="#"
                        className="text-blue-600 hover:underline"
                      >
                        terms and conditions
                      </a>
                      .
                    </label>
                  </div>

                  <div className="flex justify-center">

                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 transform hover:scale-105 flex items-center"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center opacity-50 cursor-not-allowed">
                        <ClipLoader size={20} color="#ffffff" /> <span>Submit</span>
                      </div>
                    ) : (
                      <div className="flex justify-center"> <FaPaperPlane size={20} color="#ffffff" className="mr-1" />  <span>Submit</span></div>
                     
                    )}
                  </button>

                  </div>
                
         
                </form>
              </div>
            )}
          </div>
        ) : (
          <div className="p-6">
            <button
              onClick={handleBackToList}
              className="text-blue-500 hover:underline flex items-center space-x-2"
            >
              <FiArrowLeft />
              <span>Back to List</span>
            </button>
            <h2 className="text-2xl font-bold mt-4">{selectedJob.title}</h2>
            <p className="text-gray-600 mt-2">{selectedJob.location}</p>
            <p className="text-gray-700 mt-4">{selectedJob.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Career;
