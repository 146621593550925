import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import ScrollButton from "./components/ScrollButton";
import NotFoundPage from "./components/NotFoundPage";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Home from "./Pages/Home";
import Enrol from "./components/Enrol";
import Services from "./Pages/Services";
import BlogPostDetail from "./Pages/BlogPostDetail";
import Courses from "./Pages/Courses";
import Software from "./components/Software";
import Support from "./components/Support";
import Hosting from "./components/Hosting";
import Lms from "./components/Lms";
import Crm from "./components/Crm";
import Cybersecurity from "./components/Cybersecurity";
import Career from "./Pages/Career";
import Cloud from "./components/Cloud";
import Ai from "./components/Ai";
import Data from "./components/Data";
import Compliance from "./components/Compliance";
import Transformation from "./components/Transformation";
import KidsCoding from "./Pages/KidsCoding";
import Gallery from "./Pages/Gallery.tsx";
import { setSelectedService } from "./slices/trainingSlice";
import { useDispatch } from "react-redux";
import SuccessStory from "./Pages/Success";

function App() {
  function ScrollToTopOnRouteChange({ exceptPaths }) {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setSelectedService(""));
    }, []);

    const location = useLocation();

    useEffect(() => {
      if (!exceptPaths.includes(location.pathname)) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }, [location, exceptPaths]);

    return null;
  }

  return (
    <Router>
      <div className="font-poppins overflow-x-clip">
        <Helmet>
          <title>Ikusasa Technology Solutions</title>
          <meta
            name="description"
            content="Ikusasa Technology Solutions offers innovative tech solutions for your business needs. Explore our services and products to see how we can help you achieve your goals."
          />
        </Helmet>
        <header>
          <NavBar />
        </header>
        <ScrollToTopOnRouteChange exceptPaths={["/services", "/about"]} />
        <main className="mt-[25%] md:mt-[10%] min-h-fit md:min-h-screen mx-auto overflow-x-clip">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about/*" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/*" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPostDetail />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/enrol" element={<Enrol />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/courses/:vendor" element={<Courses />} />
            <Route path="/courses/schedule/:courseName" element={<Courses />} />
            <Route path="/success" element={<SuccessStory />} />
            <Route
              path="/services/consulting/software"
              element={<Software />}
            />
            <Route path="/services/consulting/support" element={<Support />} />
            <Route
              path="/services/consulting/cybersecurity"
              element={<Cybersecurity />}
            />
            <Route path="/services/consulting/lms" element={<Lms />} />
            <Route path="/services/consulting/crm" element={<Crm />} />
            <Route path="/services/consulting/hosting" element={<Hosting />} />
            <Route path="/services/consulting/cloud" element={<Cloud />} />
            <Route path="/services/consulting/ai-ml" element={<Ai />} />
            <Route path="/services/consulting/data" element={<Data />} />
            <Route
              path="/services/consulting/compliance"
              element={<Compliance />}
            />
            <Route
              path="/services/consulting/transformation"
              element={<Transformation />}
            />
            <Route path="/kidscoding" element={<KidsCoding />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
        <ScrollButton />
      </div>
    </Router>
  );
}

export default App;
